@font-face {
  font-family: "LatoRegular";
  src: url('../src/assets/fonts/Lato-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "LatoBold";
  src: url('../src/assets/fonts/Lato-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: bold;
}

body {
  margin: 0;
  font-family: 'LatoRegular';
  padding:0;
  height:100%;
  text-align: center;
  color: var(--fontColor1) !important;
}
