.insurance-contant {
    text-align: start;
}
.insurance.card{
    margin-bottom: 30px;
    box-shadow: 0px 3px 6px var(--shadowColor);
    border: 1px solid var(--fieldBorder);
}


.insurance.card .select.input-field{
    margin-top: 10px;
}

.insurance-header {
    color: var(--red);
    font-size: 16px;
    font-family: 'Latobold';
    padding-bottom: 10px;

}

.insurance.header-content {
    display: flex;
    justify-content: space-between;

}

.infoOutlinedIcon{
    color: var(--red);
    cursor: pointer;
    font-size: 19px;
}

.insurance.bottom-content {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;

}

.insurence-bottom-text {
    font-size: 14px;
    font-family: 'Latobold';
    color: #43403F;
}
.insurence-bottom-text.disabled {
    color: #D0D0D0;
}
select.input-field{
    margin: 30px 0px;
}
.motor-insurance-data{
    text-align: justify;
}
.insurance-contant .subtext{
    padding-right: 20px ;
    font-size: 14px;
}

.custom-switch {
    border-radius: 100px;
    padding: 2px;
    width: 40px;
}

.custom-switch .circle-slide {
    height: 20px;
    width: 20px;
    border-radius: 100%;
    background-color: var(--bgColor1);
    cursor: pointer;
    transition: 0.25s ease-in-out;
}

.custom-switch.disable {
    background-color: var(--fieldBorder) !important;
}

.custom-switch.enable-off {
    background-color: var(--switchEnablebg);
}

.custom-switch.enable-on {
    background-color: var(--red);
}


.custom-switch.enable-on .circle-slide {
    transform: translateX(100%);
}

.custom-switch.disable .circle-slide {
    cursor: auto;
}
.loan-heath-insurance-data-container{
    text-align: justify;
    font-size: 16px;

}
.revised-emi{
    font-size: 20px;
    font-family: 'Latobold';

}

.insurance .page-body-checkbox{
    margin-top: 0px !important;
}

.two-wheeler-content {
    text-align: justify;
    padding-top: 5px;
}

.two-wheeler-content1 {
    text-align: left;
    font-size: 12px;
}

.plan-premium {
    display: flex;
    margin-top: -15px;
}

.insurance .leadNumber {
    font-size: 16px;
    color: var(--fontColor5);
    font-family: "LatoRegular";
    margin: 0px 0px 20px 0px;
    text-align: left;
  }