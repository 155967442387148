.vehicle-details .custom-input,
.vehicle-details .custom-select {
  margin-block: 35px 30px !important;
}
.assets_details .header-vehicle-valuation {
  text-align: start;
  color: var(--red);
  font-size: 20px;
  font-family: "LatoBold";
}
.assets_details .vehicle-valuation-card {
  width: 100%;
  box-shadow: 0px 3px 6px var(--boxShadowColor);
  border: 1px solid var(--fieldBorder);
  margin-top: 36px;
}

.vehicle-valuation-card .text-valuation {
  font-size: 32px;
  color: var(--bgColor3);
  text-align: start;
  padding: 35px 38px 36px 37px;
  
}
.vehicle-valuation-text {
  color: var(--fontColor4);
  font-size: 16px;
  text-align: left;
  margin-top: 44px;
}
.vehicle-valuation-text b {
  color: var(--red);
  font-size: 16px;
  font-family: "LatoBold";
}

.vehicle-valuation-card .text-valuation-error {
  font-size: 14px;
  color: var(--fontColor4);
  text-align: start;
  padding: 25px 0px 25px 0px;
}
.vehical-containt {
  display: flex;
}
.vehical-containt img {
  width: 106px;
  height: 106px;
  padding: 15px 0px 10px 10px;
}

.header-vehicle-valuation .leadNumber {
  font-size: 16px;
  color: var(--fontColor5);
  font-family: "LatoRegular";
  margin-bottom: 0px;
}

@media only screen and (min-width: 1100px) {
  .vehicle-valuation-card .text-valuation-error {
    padding: 40px 0px 25px 0px;
  }
}