.otp-input input {
  width: 36px !important;
  height: 44px !important;
  padding-right: 0px !important;
  border-radius: 10%;
  margin-right: 8px !important;
  font-weight: bold;
  border: 1px solid var(--fieldBorder);
  margin-top: 12px;
}
.otp-input div {
  display: block !important;
}
.otp-input-error div input {
  border: 1px solid var(----bgColor2);
}
.otp-verification-title {
  margin-top: 20px !important;
  color: var(--red);
}
.otp .otp-verification-title {
  font-family: "LatoBold";
}
#alert-dialog-slide-description {
  color: var(--fontColor);
  font-size: 16px;
  font-family: "LatoRegular";
  line-height: 19px;
}
.otp-verification-actions {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}
.otp-verification-actions button  {
  width: 136px;
  font-size: 16px;
  font-family: "LatoBold";
  height: 44px;
  border-radius: 5px;
  opacity: 1;
  white-space: nowrap;
}
.otp-verification-cancel-button,
.otp-verification-submit-button {
  margin: 0px 10px !important;
  text-transform: none !important;

}
.otp-verification-cancel-button {
  color: var(--fontColor1) !important;
  border: 1px solid var(--fontColor1) !important;
  box-shadow: 0px 2px 1px #00000029;

}
.otp-verification-submit-button {
  background-color: var(--red) !important;
}
.otp-verification-submit-button.MuiButtonBase-root.Mui-disabled
{
  color: var(--bgColor1) !important;
  box-shadow: 0px 2px 1px #00000029;
}

/* OTP Error */
.otp-input-error-msg {
  color: var(--bgColor2);
  font-size: 14px;
  
  font-family: "LatoBold";
}

.otp-text.MuiDialogContent-root {
  padding-inline: 21px 23px;
}

.otp-popup * {
  box-sizing: border-box;
}

.otp .MuiDialog-paper {
  margin: 10px;
}
