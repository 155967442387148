.hidden-adhaar {
    position: absolute;
    z-index: 99;
    background-color: white;
    top: 5px;
}

.aadhaar-field label{
    color: var(--fontColor1);
}
.aadhaar-field .MuiInputAdornment-root svg{
    color: var(--fontColor);
}
