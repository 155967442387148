.final-offer *,
.final-offer *::after,
.final-offer *::before {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
.final-offer .top-round-card-header {
	padding: 32px 23px 32px 20px;
	color: var(--red);
	font-family: "LatoBold";
	text-align: left;
	font-size: 20px;
	display: grid;
	place-content: center;
	position: relative;
	min-height: unset;
	border-top: 1px solid var(--fieldBorder);
	box-shadow: 0 1px 2px var(--fieldLabelColor);
	margin-top: 48px;
}
.final-offer .page-body-content {
	height: auto;
	background-image: url("../../assets/animation1.png");
	background-size: min(100%, 500px) auto;
	background-repeat: no-repeat;
	background-position: center bottom;
}
.final-offer .top-section {
	width: 100%;
	padding: 24px 20px 19px;
	box-shadow: 0px 3px 6px var(--shadowColor);
	position: relative;
	z-index: 99;
}
.final-offer .section-1 {
	display: flex;
	justify-content: space-between;
	max-width: 500px;
	margin: 0 auto;
	font-size: 16px;
	color: var(--fontColor4);
}
.final-offer-loan-amount,
.final-offer-value {
	text-align: start;
}
.text-content,
.value-content {
	margin-bottom: 20px;
}
.final-offer-value .value-content {
	font-family: "LatoBold";
	color: var(--fontColor4);
	display: flex;
	align-items: center;
	letter-spacing: 0.43px;
	justify-content: right;
}
.final-offer .stamp_text {
	display: flex;
	justify-content: center;
	padding-top: 20px;
}
.final-offer .stamp-text-content {
	font-size: 14px;
	font-family: "LatoBold";
	font-style: italic;
	color: var(--red);
	line-height: 25px;
}
.final-offer .stamp_text svg {
	margin-left: 5px;
	font-size: 12px;
	font-family: "LatoBold";
	color: var(--red);
}
.final-offer .content-section {
	background-color: var(--bgColor1);
}
.final-offer .section-2 {
	padding-top: 30px;
	width: 85%;
	max-width: 500px;
	margin: 0 auto;
	text-align: left;
	line-height: 25px;
	font-size: 16px;
	color: var(--fontColor3);
}
.final-offer .contents {
	margin-bottom: 30px;
}
.final-offer .data-content {
	font-family: "LatoBold";
}

.final-offer .page-body-checkbox {
	margin-left: 25px;
}
.final-offer .page-body-check-box-click {
	margin-left: 6px;
}
.emp-ref-content .MuiFormLabel-root {
	color: var(--fontColor3) !important;
}

.emp-ref-value input {
    text-transform: uppercase;
}
.emp-ref-value svg {
    color: var(--red);
	cursor: pointer;
}
.emp-ref-content .infoOutlinedIcon{
    color: var(--red);
    cursor: pointer;
    font-size: 18px;
	margin: 0px 0px -4px 5px;
}
.MuiTooltip-tooltip {
	background-color: var(--red) !important;
	font-size: 14px !important;
}

@media only screen and (min-width: 600px) {
	.final-offer .top-round-card-header {
		text-align: center;
	}
	.final-offer .section-2,
	.final-offer .page-body-checkbox {
		width: 40%;
	}
	.final-offer .page-body-checkbox {
		width: 40%;
		max-width: 500px;
		margin: 0 auto;
	}
}
