.initial-offer-page *,
.initial-offer-page *::after,
.initial-offer-page *::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.initial-offer-page .top-round-card-header .expected-emi-cont {
  max-width: 500px;
  margin-inline: auto;
}

.initial-offer-page .MuiSlider-valueLabel {
  background-color: var(--red);
}

.initial-offer-page .top-round-card-header {
  min-height: unset;
  position: relative;
  border-top: 1px solid #d0d0d0;
  box-shadow: 0 1px 2px #00000030;
  padding: 25px 20px;
}

.initial-offer-page .card-heading {
  color: var(--red);
  font-weight: bold;
  font-size: 1.15rem;
}

.initial-offer-page .top-section {
  width: 100%;
  padding: 32px;
  box-shadow: 0px 3px 6px #00000029;
  position: relative;
  z-index: 99;
}

.initial-offer-page .emi {
  font-weight: bold;
  font-size: 1.5rem;
  color: black;
  letter-spacing: 1px;
}

.initial-offer-page .top-round-card-header .card-heading {
  font-size: 20px;
  font-weight: bold;
}

.initial-offer-page .top-round-card-header .emi {
  font-size: 28px;
  font-weight: bold;
  color: var(--fontColor5);
}

.initial-offer-page button.MuiButtonBase-root {
  color: white !important;
  background-color: var(--red) !important;
}

.initial-offer-page button.MuiButtonBase-root:disabled {
  color: white !important;
  background-color: rgb(209, 209, 209) !important;
}

.flex {
  display: flex;
}

.align-center {
  align-items: center;
}

.flex-column {
  flex-direction: column;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.relative {
  position: relative;
}

.slidebar-range-info {
  position: absolute;
  width: 100%;
  top: 27px;
  font-weight: bold;
  font-size: 0.8rem;
}

.MuiSlider-root {
  z-index: 99;
}

.slider-label {
  font-size: 16px;
  color: var(--fontColor);
  line-height: 19px;
  font-weight: bold;
  text-align: left;
}

.slider-relative-inp {
  margin-top: 18px;
}

.slider-relative-inp .MuiInputBase-root {
  font-size: 20px;
}

.slider-relative-inp .MuiInputBase-root input {
  padding-bottom: 0;
}

.tenure-slider-cont {
  margin-top: 41px;
}

.interest-rate-cont {
  margin-top: 34px;
}

.gen-emi-btn-cont {
  padding: 0 20px;
  margin-top: 45px;
  margin-bottom: 10px;
}

.btn-primary {
  background-color: var(--red);
  font-size: 20px !important;
  height: 40px;
  text-transform: capitalize !important;
}

.bg-white {
  background-color: white;
}

.footer-fix-center .page-body-contents {
  width: 100%;
  padding: 0 20px;
  margin: 0 auto;
  max-width: 500px;
}

.loan-reference-info {
  padding: 43px 20px;
  text-align: left;
  padding-bottom: 0;
  color: var(--fontColor5);
}

.loan-reference-info p {
  text-align: left;
}

.mb-20 {
  margin-bottom: 20px;
}

.month-label-input-root {
  position: relative;
}

.month-label-input-root .month-label {
  position: absolute;
  left: 27px;
  top: 6.5px;
  font-size: 20px;
}

.month-label-input-root .month-label.ml-neg {
  left: 20px;
}

.month-label-input-root .month-label.error {
  color: var(--bgColor2) !important;
}

.initial-offer-page .page-body-content {
  background-image: url("../../assets/animation1.png");
  background-size: min(100%, 500px) auto;
  background-repeat: no-repeat;
  background-position: center bottom;
}
.initial-offer-page .adornment {
  color: var(--fontColor);
  position: relative;
  bottom: -3px;
}
.initial-offer-page input {
  font-family: "latoRegular"  !important;
}

/* pyro */
.pyro {
  position: relative;
  z-index: 1;
  top: -90px;
}
.offer-for-you-popup .MuiBox-root {
  position: relative;
  z-index: 2;
}
.offer-for-you-popup .MuiPaper-root {
  overflow: visible;
}
.blast-2 .pyro {
  top: 320px;
}
.pyro > .before,
.pyro > .after {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  box-shadow: 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff,
    0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff,
    0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff,
    0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff,
    0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff,
    0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff,
    0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff,
    0 0 #fff, 0 0 #fff, 0 0 #fff;
  -moz-animation: 1s bang ease-out infinite backwards,
    1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -webkit-animation: 1s bang ease-out infinite backwards,
    1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -o-animation: 1s bang ease-out infinite backwards,
    1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -ms-animation: 1s bang ease-out infinite backwards,
    1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  animation: 1s bang ease-out infinite backwards,
    1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
}

.pyro > .after {
  -moz-animation-delay: 1.25s, 1.25s, 1.25s;
  -webkit-animation-delay: 1.25s, 1.25s, 1.25s;
  -o-animation-delay: 1.25s, 1.25s, 1.25s;
  -ms-animation-delay: 1.25s, 1.25s, 1.25s;
  animation-delay: 1.25s, 1.25s, 1.25s;
  -moz-animation-duration: 1.25s, 1.25s, 6.25s;
  -webkit-animation-duration: 1.25s, 1.25s, 6.25s;
  -o-animation-duration: 1.25s, 1.25s, 6.25s;
  -ms-animation-duration: 1.25s, 1.25s, 6.25s;
  animation-duration: 1.25s, 1.25s, 6.25s;
}

@-webkit-keyframes bang {
  to {
    box-shadow: -243px -266.6666666667px #00ff4d,
      188px -188.6666666667px #ff0009, -194px -65.6666666667px #ff002f,
      185px -409.6666666667px #ffae00, -163px -381.6666666667px #5100ff,
      197px 16.3333333333px #0080ff, 213px -47.6666666667px #eaff00,
      223px -250.6666666667px #ccff00, 129px -232.6666666667px #73ff00,
      212px -308.6666666667px #ff00e6, -220px -350.6666666667px #ff8000,
      -89px -93.6666666667px #00ffcc, 102px -261.6666666667px #ff008c,
      237px -261.6666666667px #ffd500, -8px -120.6666666667px #b300ff,
      -84px 22.3333333333px #ff00b3, -82px -119.6666666667px #62ff00,
      3px -307.6666666667px #0011ff, -228px -341.6666666667px #00ffd0,
      40px -158.6666666667px yellow, 146px -332.6666666667px #9100ff,
      -206px -139.6666666667px #0033ff, -111px -94.6666666667px #0055ff,
      11px -379.6666666667px #ff6f00, -112px -213.6666666667px #ff0055,
      199px -388.6666666667px #002fff, -40px 59.3333333333px #fb00ff,
      92px -79.6666666667px #1e00ff, -86px -47.6666666667px #fff200,
      -239px -59.6666666667px #0080ff, 213px -309.6666666667px #00e1ff,
      -187px -13.6666666667px #00ff1e, 77px -272.6666666667px #04ff00,
      -118px -106.6666666667px #4d00ff, 152px 21.3333333333px #ff00aa,
      -121px -270.6666666667px #eeff00, -100px -18.6666666667px #ffcc00,
      104px -395.6666666667px #ffaa00, 159px -321.6666666667px #ff00ae,
      188px -2.6666666667px #f700ff, -44px -63.6666666667px #d5ff00,
      -235px -38.6666666667px #ff00f2, 244px -8.6666666667px #ff7700,
      -38px -109.6666666667px #00ff40, 84px -335.6666666667px #9500ff,
      145px -49.6666666667px #ff00d5, 203px -331.6666666667px #1100ff,
      131px -31.6666666667px #00ff44, 200px 52.3333333333px #00ff1a,
      -36px 66.3333333333px #1eff00, -232px -198.6666666667px #002fff;
  }
}
@-moz-keyframes bang {
  to {
    box-shadow: -243px -266.6666666667px #00ff4d,
      188px -188.6666666667px #ff0009, -194px -65.6666666667px #ff002f,
      185px -409.6666666667px #ffae00, -163px -381.6666666667px #5100ff,
      197px 16.3333333333px #0080ff, 213px -47.6666666667px #eaff00,
      223px -250.6666666667px #ccff00, 129px -232.6666666667px #73ff00,
      212px -308.6666666667px #ff00e6, -220px -350.6666666667px #ff8000,
      -89px -93.6666666667px #00ffcc, 102px -261.6666666667px #ff008c,
      237px -261.6666666667px #ffd500, -8px -120.6666666667px #b300ff,
      -84px 22.3333333333px #ff00b3, -82px -119.6666666667px #62ff00,
      3px -307.6666666667px #0011ff, -228px -341.6666666667px #00ffd0,
      40px -158.6666666667px yellow, 146px -332.6666666667px #9100ff,
      -206px -139.6666666667px #0033ff, -111px -94.6666666667px #0055ff,
      11px -379.6666666667px #ff6f00, -112px -213.6666666667px #ff0055,
      199px -388.6666666667px #002fff, -40px 59.3333333333px #fb00ff,
      92px -79.6666666667px #1e00ff, -86px -47.6666666667px #fff200,
      -239px -59.6666666667px #0080ff, 213px -309.6666666667px #00e1ff,
      -187px -13.6666666667px #00ff1e, 77px -272.6666666667px #04ff00,
      -118px -106.6666666667px #4d00ff, 152px 21.3333333333px #ff00aa,
      -121px -270.6666666667px #eeff00, -100px -18.6666666667px #ffcc00,
      104px -395.6666666667px #ffaa00, 159px -321.6666666667px #ff00ae,
      188px -2.6666666667px #f700ff, -44px -63.6666666667px #d5ff00,
      -235px -38.6666666667px #ff00f2, 244px -8.6666666667px #ff7700,
      -38px -109.6666666667px #00ff40, 84px -335.6666666667px #9500ff,
      145px -49.6666666667px #ff00d5, 203px -331.6666666667px #1100ff,
      131px -31.6666666667px #00ff44, 200px 52.3333333333px #00ff1a,
      -36px 66.3333333333px #1eff00, -232px -198.6666666667px #002fff;
  }
}
@-o-keyframes bang {
  to {
    box-shadow: -243px -266.6666666667px #00ff4d,
      188px -188.6666666667px #ff0009, -194px -65.6666666667px #ff002f,
      185px -409.6666666667px #ffae00, -163px -381.6666666667px #5100ff,
      197px 16.3333333333px #0080ff, 213px -47.6666666667px #eaff00,
      223px -250.6666666667px #ccff00, 129px -232.6666666667px #73ff00,
      212px -308.6666666667px #ff00e6, -220px -350.6666666667px #ff8000,
      -89px -93.6666666667px #00ffcc, 102px -261.6666666667px #ff008c,
      237px -261.6666666667px #ffd500, -8px -120.6666666667px #b300ff,
      -84px 22.3333333333px #ff00b3, -82px -119.6666666667px #62ff00,
      3px -307.6666666667px #0011ff, -228px -341.6666666667px #00ffd0,
      40px -158.6666666667px yellow, 146px -332.6666666667px #9100ff,
      -206px -139.6666666667px #0033ff, -111px -94.6666666667px #0055ff,
      11px -379.6666666667px #ff6f00, -112px -213.6666666667px #ff0055,
      199px -388.6666666667px #002fff, -40px 59.3333333333px #fb00ff,
      92px -79.6666666667px #1e00ff, -86px -47.6666666667px #fff200,
      -239px -59.6666666667px #0080ff, 213px -309.6666666667px #00e1ff,
      -187px -13.6666666667px #00ff1e, 77px -272.6666666667px #04ff00,
      -118px -106.6666666667px #4d00ff, 152px 21.3333333333px #ff00aa,
      -121px -270.6666666667px #eeff00, -100px -18.6666666667px #ffcc00,
      104px -395.6666666667px #ffaa00, 159px -321.6666666667px #ff00ae,
      188px -2.6666666667px #f700ff, -44px -63.6666666667px #d5ff00,
      -235px -38.6666666667px #ff00f2, 244px -8.6666666667px #ff7700,
      -38px -109.6666666667px #00ff40, 84px -335.6666666667px #9500ff,
      145px -49.6666666667px #ff00d5, 203px -331.6666666667px #1100ff,
      131px -31.6666666667px #00ff44, 200px 52.3333333333px #00ff1a,
      -36px 66.3333333333px #1eff00, -232px -198.6666666667px #002fff;
  }
}
@-ms-keyframes bang {
  to {
    box-shadow: -243px -266.6666666667px #00ff4d,
      188px -188.6666666667px #ff0009, -194px -65.6666666667px #ff002f,
      185px -409.6666666667px #ffae00, -163px -381.6666666667px #5100ff,
      197px 16.3333333333px #0080ff, 213px -47.6666666667px #eaff00,
      223px -250.6666666667px #ccff00, 129px -232.6666666667px #73ff00,
      212px -308.6666666667px #ff00e6, -220px -350.6666666667px #ff8000,
      -89px -93.6666666667px #00ffcc, 102px -261.6666666667px #ff008c,
      237px -261.6666666667px #ffd500, -8px -120.6666666667px #b300ff,
      -84px 22.3333333333px #ff00b3, -82px -119.6666666667px #62ff00,
      3px -307.6666666667px #0011ff, -228px -341.6666666667px #00ffd0,
      40px -158.6666666667px yellow, 146px -332.6666666667px #9100ff,
      -206px -139.6666666667px #0033ff, -111px -94.6666666667px #0055ff,
      11px -379.6666666667px #ff6f00, -112px -213.6666666667px #ff0055,
      199px -388.6666666667px #002fff, -40px 59.3333333333px #fb00ff,
      92px -79.6666666667px #1e00ff, -86px -47.6666666667px #fff200,
      -239px -59.6666666667px #0080ff, 213px -309.6666666667px #00e1ff,
      -187px -13.6666666667px #00ff1e, 77px -272.6666666667px #04ff00,
      -118px -106.6666666667px #4d00ff, 152px 21.3333333333px #ff00aa,
      -121px -270.6666666667px #eeff00, -100px -18.6666666667px #ffcc00,
      104px -395.6666666667px #ffaa00, 159px -321.6666666667px #ff00ae,
      188px -2.6666666667px #f700ff, -44px -63.6666666667px #d5ff00,
      -235px -38.6666666667px #ff00f2, 244px -8.6666666667px #ff7700,
      -38px -109.6666666667px #00ff40, 84px -335.6666666667px #9500ff,
      145px -49.6666666667px #ff00d5, 203px -331.6666666667px #1100ff,
      131px -31.6666666667px #00ff44, 200px 52.3333333333px #00ff1a,
      -36px 66.3333333333px #1eff00, -232px -198.6666666667px #002fff;
  }
}
@keyframes bang {
  to {
    box-shadow: -243px -266.6666666667px #00ff4d,
      188px -188.6666666667px #ff0009, -194px -65.6666666667px #ff002f,
      185px -409.6666666667px #ffae00, -163px -381.6666666667px #5100ff,
      197px 16.3333333333px #0080ff, 213px -47.6666666667px #eaff00,
      223px -250.6666666667px #ccff00, 129px -232.6666666667px #73ff00,
      212px -308.6666666667px #ff00e6, -220px -350.6666666667px #ff8000,
      -89px -93.6666666667px #00ffcc, 102px -261.6666666667px #ff008c,
      237px -261.6666666667px #ffd500, -8px -120.6666666667px #b300ff,
      -84px 22.3333333333px #ff00b3, -82px -119.6666666667px #62ff00,
      3px -307.6666666667px #0011ff, -228px -341.6666666667px #00ffd0,
      40px -158.6666666667px yellow, 146px -332.6666666667px #9100ff,
      -206px -139.6666666667px #0033ff, -111px -94.6666666667px #0055ff,
      11px -379.6666666667px #ff6f00, -112px -213.6666666667px #ff0055,
      199px -388.6666666667px #002fff, -40px 59.3333333333px #fb00ff,
      92px -79.6666666667px #1e00ff, -86px -47.6666666667px #fff200,
      -239px -59.6666666667px #0080ff, 213px -309.6666666667px #00e1ff,
      -187px -13.6666666667px #00ff1e, 77px -272.6666666667px #04ff00,
      -118px -106.6666666667px #4d00ff, 152px 21.3333333333px #ff00aa,
      -121px -270.6666666667px #eeff00, -100px -18.6666666667px #ffcc00,
      104px -395.6666666667px #ffaa00, 159px -321.6666666667px #ff00ae,
      188px -2.6666666667px #f700ff, -44px -63.6666666667px #d5ff00,
      -235px -38.6666666667px #ff00f2, 244px -8.6666666667px #ff7700,
      -38px -109.6666666667px #00ff40, 84px -335.6666666667px #9500ff,
      145px -49.6666666667px #ff00d5, 203px -331.6666666667px #1100ff,
      131px -31.6666666667px #00ff44, 200px 52.3333333333px #00ff1a,
      -36px 66.3333333333px #1eff00, -232px -198.6666666667px #002fff;
  }
}
@-webkit-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@-moz-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@-o-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@-ms-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@-webkit-keyframes position {
  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }
  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }
  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }
  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }
  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}
@-moz-keyframes position {
  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }
  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }
  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }
  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }
  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}
@-o-keyframes position {
  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }
  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }
  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }
  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }
  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}
@-ms-keyframes position {
  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }
  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }
  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }
  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }
  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}
@keyframes position {
  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }
  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }
  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }
  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }
  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}
