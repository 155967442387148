.animation-container {
  background-image: url('../../assets/animation1.png');
  background-repeat: repeat;
  height: auto;
  padding: 100px 0px;
}
.animation-bottom-text {
  background-color: var(--red) !important;
  padding: 20px;
  color: white !important;
  font-weight: bold;
}
.animation-text-box {
  /* margin-top: 30px !important; */
  font-weight: bold;
  font-size: 24px;
  color: var(--fontColor2);
  /* width: 70%; */
  padding: 20px 45px;
}
.animation-img {
  width: 200px;
  margin-bottom: 30px;
}
.animation-wrapper {
  top: 55px;
  position: fixed;
  z-index: 99;
  background: var(--bgColor);
  width: 100%;
}

@media only screen and (min-width: 1023px) {
  .animation-wrapper {
    background: white;
    height: 100%;
  }
}