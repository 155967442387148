.vehicle_dashboard .top-round-card-header{
    padding: 24px 20px;
    border-bottom: 1px solid rgb(184, 184, 184);
}
.vehicle_dashboard .bottom-section .MuiButton-root{
    display: none;
}
.vehicle_dashboard .top-round-card-header .MuiButton-root,
.vehicle_dashboard .section-3 .MuiButton-root{
    height: 40px;
    max-width: 600px;
    font-weight: bold;
    width: 100%;
}
.vehicle_dashboard .top-round-card-header .MuiButton-root{
    background-color: var(--red);
    text-transform: none !important;
}
.vehicle_dashboard .section-2{
    padding: 10px 0px 20px 0px;
    max-width: 600px;
    text-align: start;
    margin-inline: auto;
    color: var(--fontColor3)
}
.vehicle_dashboard .section-2 .data{
    margin-block: 5px;
}
.vehicle_dashboard .section-2 .progress{
    font-style: italic;
    color: var(--circleColor);
}
.vehicle_dashboard .section-2 .completed{
    font-style: italic;
    color: var(--bgColor3);
}
.vehicle_dashboard .section-2 .rejected{
    font-style: italic;
    color: var(--red);
}

.vehicle_dashboard .section-3 .MuiButton-root{
    border-color: var(--red);
    color: var(--red);
    text-transform: none !important;
    box-shadow: 0px 2px 1px #00000029;
}
.bottom-shadow {
    padding: 20px 0px;
    box-shadow: 0px 3px 6px #00000029;
    position: relative;
    width: 100%;
}
.loan-amount {
    font-size: 28px;
    font-weight: bold;
    padding: 0px 0px 20px 0px;
    margin-left: -5px;
}