.assistance-card-container{
    display: flex;
    justify-content: center;
    width: 100%;
}
.assistance-card-container .assistance-card{
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: fit-content;
    border: 0.75px solid var(--red);
    border-radius: 40px;
    padding: 6px 6px;
    min-width: 330px;
}
.assistance-card-container .assistance-card .message-text{
    color: #8E9295;
    margin-left: 10px;
    font-size: 12px;
}