.page-body-checkbox, .page-body-checkbox1 {
    margin-top: 30px;
}
.page-body-checkbox p {
    text-align: left;
    margin: 3px 0px 0px 0px;
    font-size: 14px;
}
.page-body-checkbox1 p {
    text-align: left;
    margin: 0px;
    font-size: 14px;
}
input[type='checkbox'] {
    accent-color: var(--red);
    margin: 3px 3px 3px 0px;
    width: 18px;
    height: 18px;
    cursor: pointer;
}
.page-body-check-box-click {
    margin-left: 6px;
    color: var(--linkColor);
    text-decoration: underline;
    padding-bottom: 2px;
    cursor: pointer;
    text-underline-offset: 2px;
}
