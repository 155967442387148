.TermsAndConditionsWrapper {
    top: 55px;
    position: fixed;
    z-index: 99;
    padding-top: 44px;
    background: var(--bgColor);
}

.TermsAndConditionsWrapper .terms-conditions-container {
    padding-top: 0 !important;
}

.TermsAndConditionsWrapper .top-round-card-header {
    margin-top: 0 !important;
}

@media only screen and (max-width: 500px) {
    .TermsAndConditionsWrapper {
        padding-top: 15px;
    }
}