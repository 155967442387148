.app-logo {
    height: 25px;
    padding-left: 20%;
}

.top-header-nav {
    display: flex;
}

.top-header-nav .go-back {
    padding-left: 20px;
}

.top-header-nav span {
    flex: 1;
}

.top-header-nav span.neg-left-margin i {
    margin-left: -40px;
}

@media only screen and (max-width: 600px) {
    .app-logo {
        height: 20px;
        padding: 3% 0% 0% 20%;
    }
  }