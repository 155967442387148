.otp-resend-button {
    text-decoration: underline !important;
    color: #000000 !important;
    margin-top: 15px !important;
    cursor: pointer;
    font-size: 14px;
    font-family: "LatoBold";
}
.otp-timer {
    text-align: center !important;
    color: #6A6A6A !important;
    margin-top: 15px !important;
    font-size:14px;
    font-family: "LatoBold";
}
