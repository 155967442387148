/* Toaster */
.notfound-toaster {
  margin-top: 150px;
}
.notfound-toaster div:first-child {
  top: 150px;
  font-weight: bold;
  font-size: 22px;
}
.css-1vooibu-MuiSvgIcon-root {
  height: 20px !important;
  width: 20px !important;
}
