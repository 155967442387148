:root {
  --red: #870016;
  --fieldLabelColor: #8e9295;
  --bgColor: #f2f2f2;
  --fontColor: #1c1b1b;
  --fontColor1: #595856;
  --fontColor2: #3a1413;
  --fontColor3: #43403f;
  --fontColor4: #191919;
  --fontColor5: #161616;
  --linkColor: #2680eb;
  --bgColor1: #ffffff;
  --bgColor2: #e64c66;
  --bgColor3: #419b56;
  --fieldBorder: #d0d0d0;
  --circleColor: #f4b348;
  --formLabelColorPrimary: #595654;
  --radioBtnBorder: #6a6a6a;
  --boxShadowColor: #00000029;
  --shadowColor: #00000029;
  --switchEnablebg: #bfbfbf;
}

.desktop-header {
  background-color: var(--bgColor1);
  height: 40px;
  padding-top: 15px !important;
}

.desktop-header span {
  color: var(--red);
  font-size: 20px;
  font-weight: bold;
}

.desktop-header .MuiSvgIcon-root {
  float: left;
  padding-left: 7%;
  margin-right: -14%;
}

.field-label {
  font-size: 14px;
  color: var(--fontColor1);
  float: left !important;
  text-align: left !important;
  padding-top: 5px;
  margin-bottom: 20px;
  width: 100%;
}

/* Main Header and Footer */
.top-background {
  position: relative;
  top: 0;
}

.top-background img,
.bottom-background img,
.bottom-section img {
  width: 100%;
  height: auto;
  max-height: 100px;
}

/* Background */
.app-body {
  background-color: var(--bgColor);
  height: 100vh;
}

.page-body-contents,
.top-header,
.landing-page-body {
  margin: 0 auto;
  width: 85%;
}

.body-content {
  margin-top: 50px;
  /* min-height: 100%; */
  background-color: var(--bgColor);
}

.page-body-content {
  background-color: var(--bgColor1);
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.bottom-section .page-body-contents {
  display: flex;
  flex-direction: row;
  transform: translateY(70px);
  justify-content: space-around;
}

/* Divider */
.divider {
  width: 100%;
  height: auto;
  margin-top: 70px;
}

.top-round-card-header {
  min-height: 50px;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  background: white;
  box-shadow: 0px 0 6px rgba(0, 0, 0, 0.2);
  margin-top: 34px;
}

/* Error */
.error {
  color: var(--bgColor2) !important;
  float: left;
  padding: 5px 0px;
}

.field-error
  .css-1ptx2yq-MuiInputBase-root-MuiInput-root:hover:not(.Mui-disabled):before {
  border-bottom: 2px solid var(--bgColor2) !important;
}

/* Toaster */
.success-toaster div:first-child {
  top: 40px;
  height: 40px;
  font-weight: bold;
  font-size: 16px;
  right: 15px;
}

/* Input Field */
.Mui-focused {
  color: var(--fontColor1) !important;
}

.MuiOutlinedInput-root.Mui-focused {
  border-bottom: 1px solid var(--fieldBorder) !important;
}

.Mui-disabled {
  color: var(--fieldLabelColor) !important;
}

.MuiDialog-paper {
  margin: 10px !important;
}

.italic-text {
  font-style: italic;
  color: var(--fontColor1);
}

.top-z-index {
  z-index: 99999 !important;
}

.MuiInput-root::before{
  border-bottom: 1px solid var(--fieldBorder) !important;
  animation-name: unset !important;
}
.MuiInput-root:after{
  border-bottom: none !important;
}
.error-input .MuiInput-root::before{
  border-bottom: 1px solid var(--bgColor2) !important;
}

@media only screen and (min-width: 601px) {
  .page-body-contents,
  .welcome-content {
    margin: 0 auto;
    width: 40%;
  }

  .top-background img,
  .bottom-background img,
  .bottom-section img {
    height: 100px;
  }

  .welcome-img {
    width: 150px !important;
  }

  .welcome-content {
    margin-top: 80px !important;
  }

  .divider {
    margin-top: 20px;
  }

  /* .page-container {
    height: calc(100vh - 80px);
  } */
}

@media only screen and (max-width: 600px) {
  .success-toaster div:first-child {
    font-size: 14px;
  }
}
