.error-actions {
  margin: 0 auto;
  padding-bottom: 10px;
}
.error-submit-button {
  margin: 0px 10px !important;
  text-transform: none !important;
  background-color: var(--red) !important;
  margin-top: 30px !important;
}
.error-title {
  color: var(--red);
  margin-block: 10px !important;
}
.error-submit-button.MuiButtonBase-root.MuiButton-root {
  font-size: 16px;
  font-family: "LatoBold";
  width: 136px;
  height: 44px;
  padding: 12px 33px;
}
.error-title.MuiTypography-root.MuiTypography-h6 {
  font-size: 20px;
  font-family: "LatoBold";
}
.error .MuiPaper-root.MuiPaper-elevation {
  max-width: 97vw;
  margin: 0px;
  padding-block: 5px;
}
.error .alert-dialog {
  color: var(--fontColor);
  font-size: 16px;
  font-family: "LatoRegular";
  line-height: 19px;
  padding: 0px;
  margin-bottom: 5px;
}
