.preApproved-offerPage-top-round-card-header {
  min-height: 50px;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  background: var(--bgColor1);
  box-shadow: 0px 0 6px var(--shadowColor);
  margin-top: 38px;
  z-index: 1;
}
.preApproved-offerPage-page-body-content {
  background-color: var(--bgColor1);
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  z-index: 1;
}
.header-text {
  font-size: 20px;
  color: var(--red);
  font-family: "LatoBold";
  text-align: start;
  margin-bottom: 40px;
}

.pre-offer-page-container {
  width: 100%;
  border: 1px solid var(--fieldBorder);
  box-shadow: 0px 3px 6px var(--shadowColor);
  margin-bottom: 36px;
}

.pre-offer-page-container .pre-offer-header {
  background-color: var(--red);
  color: var(--bgColor1);
  display: flex;
  align-items: center;
}

.pre-offer-header img {
  height: 35px;
  padding: 10px 0px;
  padding-left: 5px;
}

.pre-offer-content {
  display: flex;
  justify-content: space-around;
  margin-top: 22px;
}

.pre-offer-loan-amount,
.pre-offer-value {
  text-align: start;
}

.pre-offer-loan-amount .content-text,
.pre-offer-value .content-value {
  margin-bottom: 20px;
}

.content-text {
  color: var(--fontColor3);
}

.content-value {
  font-weight: bold;
  color: var(--fontColor);
  display: flex;
  align-items: center;
  letter-spacing: 0.43px;
}

.avail-offer-button {
  width: 85%;
  height: 40px;
  border-radius: 5px 5px 5px 5px;
  border: 1px solid var(--red);
  color: var(--red);
  box-shadow: 0px 2px 1px var(--shadowColor);
  background-color: var(--bgColor1);
  font-size: 16px;
  cursor: pointer;
}

.pre-approved.link-text {
  color: var(--linkColor);
  margin: 12px auto 23px auto;
  font-size: 14px;
  text-decoration-line: underline;
  text-underline-offset: 3px !important;
  cursor: pointer;
}
