.insurance-terms-conditions-container {
    padding-top: 50px;
    height: calc(100vh - 153px);
}

.insurance-terms-heading {
    margin: 0;
    background: var(--bgColor1);
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 0px 0px 10px 0px;
    position: sticky;
    top: 0;
}

.insurance-terms-heading svg {
    float: right;
    width: 35px;
    height: 35px;
    margin-top: -5px;
    cursor: pointer;
}


@media only screen and (min-width: 601px) {
    .insurance-consent-content {
        height: 300px !important;
    }
    
}

.insurance-consent-content {
    text-align: left !important;
    height: calc(100vh - 260px) !important;
    float: left;
    overflow: scroll;
    scroll-behavior: smooth;
    color: var(--fontColor);
}
.insurance-consent-content::-webkit-scrollbar {
    display: none;
}
