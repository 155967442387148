/* Desktop Stepper */
.page-container.roadmap .page-body-content .page-body-contents{
    width: 85% !important;
}

.Mui-completed .MuiStepConnector-line,
.Mui-active .MuiStepConnector-line{
    border-color: var(--bgColor3);
    border-width: 2px;
}
.desktop_stepper .MuiStepper-root{
    width: 95vw;
    transform: translateX(-5vw);
}
.MuiStepIcon-root.Mui-completed{
    color: var(--bgColor3)!important;
    border: 1px solid var(--bgColor3);
}
.MuiStepIcon-root.Mui-active{
    color: white!important;
    border: 1px solid var(--bgColor3);
    border-radius: 100%;
}
.MuiStepIcon-root{
    color: white!important;
    border: 1px solid rgb(158,158,158);
    border-radius: 100%;
    background-color: white!important;
    z-index: 5;
}
.roadmap .stepper-completed-description{
    text-align: center;
    color: var(--bgColor3);
    font-size: 12px;
    margin-top: 5px;
}

.roadmap .stepper-completed-description i{
    white-space: nowrap;
}

.desktop_stepper .stepper-label{
    color: black;
    font-weight: bold;
}
.desktop_stepper .stepper-description{
    color: black;
}
.desktop_stepper .disabled.stepper-label,
.desktop_stepper .disabled.stepper-description{
    color: rgb(193, 193, 193);
}
.desktop_stepper .Mui-completed .stepper-label,
.desktop_stepper .Mui-completed .stepper-description{
    color: black;
}
.desktop_stepper .MuiStepConnector-line{
    border-top-style: dashed!important;
    width: 110%;
    transform: translateX(-10px);
}
.desktop_stepper .Mui-active .MuiStepConnector-line{
    border-top-style: solid!important;
}
.desktop_stepper .Mui-completed .MuiStepConnector-line{
    border-top-style: solid!important;
}

/* Mobile Stepper */

.mobile_stepper{
    display: none;
    margin-bottom: -65px;
}

.mobile_stepper .MuiStepLabel-label{
    display: flex;
    align-items: center;
    font-size: 16px;
    font-family: "LatoBold";
} 
.mobile_stepper .MuiStepLabel-label img{
    margin-right: 10px;
}
.mobile_stepper .stepper-description{
    margin-left: 77px;
    text-align: start;
    transform: translateY(-20px);
}
.mobile_stepper .stepper-completed-description{
    margin-left: 77px;
    text-align: start;
    transform: translateY(-15px);
    color: var(--bgColor3);
    font-size: 12px;
}
.mobile_stepper .MuiStepConnector-line{
    border-left-style: dashed!important;
}
.mobile_stepper .Mui-active .MuiStepConnector-line{
    border-left-style: solid!important;
}
.mobile_stepper .Mui-completed .MuiStepConnector-line{
    border-left-style: solid!important;
}

@media only screen and (max-width: 1100px){
    .page-container.roadmap .page-body-content .page-body-contents{
        margin-inline: auto;
        width: auto;
    }
    .desktop_stepper{
        display: none;
    }
    .mobile_stepper{
        display: block;
    }
}


.circle, .circle-correct, .circle_active, .circle-correct-tick {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    padding: 0px 4px 4px 0px;
    margin-top: -20px;
}
.circle {
    border: 1px solid gray;
    background-color: white;
}
.circle_active {
    border: 1px solid green;
    background-color: white;
}
.circle-correct {
    border: 1px solid #419B56;
}
.circle-correct-tick {
    background-color: #419B56;
    border: 1px solid #419B56;
}
.step-correct-tick {
    border-left: 1px solid #419B56;
    height: 70px;
    margin-left: 8px;
    margin-top: -26px;
}
.incompleted-steps .stepper-label, .incompleted-steps .stepper-body {
    color: #8E9295;
}
.step {
    border-left: 1px dotted gray;
    height: 85px;
    margin: -6px 0px 0px 6px;
}
.completed-steps {
    border-left: 1px solid #419B56;
    height: 85px;
    margin: -6px 0px 0px 6px;
}
.stepper-contents {
    display: flex;
    align-items: center;
    color: var(--fontColor3);
}
.stepper-label {
    margin: 0px;
    font-weight: bold;
}
.stepper_description{
    font-size: 14px;
    font-family: "LatoRegular";
}
.stepper_completed_description {
    color: #419B56;
    font-size: 11px;
    float: left;
    width: 100%;
}
.stepper-img {
    max-width: 60px;
    margin: 0px 5px;
}
.stepper-block {
    height: 100px;
}
.mobile_stepper .MuiStepLabel-iconContainer {
    margin-top: 25px;
}
