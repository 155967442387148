.perfious-redirect-page  {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 55px;
    background: var(--bgColor);
    display: flex;
    justify-content: center;
}

.perfious-redirect-page .bottom-background {
  display: none;
}
.perfious-redirect-page .body-content, 
.perfious-redirect-page .welcome-content
{
  margin-top: 0;
}
.perfious-redirect-page .welcome-img {
    min-height: unset !important;
}
.perfious-redirect-page .welcome-text-box {
   font-size:24px !important;
   margin-bottom: 29px;
}

.message-hint-wrap {
    position: relative;
    height:max-content;
    margin-top: 126px;
}
.perfious-bg { 
    position : absolute;
    height: 135px;
    left: 0;
    width: 100%;
}
.perfious-bg.bg-1 { 
    top: -92px ;
    transform: scaleX(-1);
}
.perfious-bg.bg-2 { 
  bottom : -100px;
}
@media only screen and (min-width: 601px){
    .perfious-redirect-page .welcome-content {
     width:  unset !important;
    }
}