.isMobile {
  margin-top: 0px !important;
}
.MuiSnackbar-root {
  z-index: 10000000 !important;
  margin: 0 auto;
  width: 85%;
  margin-top: 53px;
  position: absolute !important;
}
.MuiSnackbar-root .MuiPaper-root {
  padding: 0px 16px;
  height: 25px;
}
.MuiAlert-standard {
  display: flex !important;
  align-items: center !important;
}

.MuiAlert-message {
  font-size: 13px !important;
}
.MuiAlert-standardSuccess .MuiAlert-icon,
.MuiAlert-standardSuccess .MuiAlert-message {
  color: var(--bgColor3) !important;
}
.MuiAlert-standardError .MuiAlert-icon,
.MuiAlert-standardError .MuiAlert-message {
  color: var(--red) !important;
  margin-right: 4px;
}
.MuiAlert-standardSuccess .MuiAlert-icon {
  padding: 5px 0px !important;
}

@media (min-width: 600px) and (max-width: 1400px) {
  .MuiSnackbar-root {
    width: 50%;
    margin-right: 25%;
    top: 10px !important;
  }
}

@media (min-width: 500px) and (max-width: 600px) {
  .MuiSnackbar-root {
    width: 60%;
  }
}

@media (min-width: 1400px) {
  .MuiSnackbar-root {
    top: 10px !important;
    width: 60%;
    margin-right: 20%;
  }
}