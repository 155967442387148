
.basic_details .custom-input {
    margin: 20px 0px !important;
}

.page-body-content .basic_details_stepper .MuiPaper-root {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 0;
    background-color: transparent;
    transform: translateY(-44%);
}

.page-body-content .basic_details_stepper .MuiPaper-root .MuiStepConnector-root{
    width: 95%;
    transform: translateX(-10px);
    z-index: -1;
}
.basic_details_stepper {
    position: absolute;
    width: 100%;
    margin-top: -48px;
    z-index: 5;
    transform: translateX(-30px);
}

.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
    padding: 0px 10px 0px 0px !important;
}

.page-body-content .MuiPaper-root .stepper_svg {
    border: 1px solid var(--circleColor) !important;
    height: 30px;
    width: 30px;
    border-radius: 100%;
    transform: translateY(-5px);
    display: grid;
    place-items: center;
    background-color: var(--bgColor1);
}
.page-body-content .MuiPaper-root .stepper_svg img {
    height: 90%;
    width: 90%;
    padding: 0;
}
.page-body-content .MuiPaper-root .stepper_svg svg {
    transform: scale(0.8);
    color: var(--red);
}

.page-body-content .MuiPaper-root .Mui-disabled .stepper_svg {
    border: 1px solid var(--fieldBorder) !important;
    height: 20px;
    width: 20px;
    transform: translateY(0px);
}

.page-body-content .MuiPaper-root .Mui-disabled .stepper_svg svg {
    color: rgb(202, 202, 202);
}

.page-body-content .MuiPaper-root .MuiStep-completed .stepper_svg {
    background-color: var(--red);
    border: 2px solid var(--red) !important;
}

.page-body-content .MuiPaper-root .MuiStep-completed .stepper_svg svg {
    color: white;
}

.page-body-content .MuiPaper-root .MuiStepConnector-completed .MuiStepConnector-line,
.page-body-content .MuiPaper-root .MuiStepConnector-active .MuiStepConnector-line {
    border-color: orange;
}

.user_details {
    text-align: left;
    color: var(--fontColor4);
}

.user_details .heading {
    font-size: 20px;
    color: var(--red);
    font-weight: bold;
}

.user_details .heading .leadNumber {
    font-size: 16px;
    color: var(--fontColor5);
    font-weight: normal;
    margin-bottom: 0px;
}

.user_details .name {
    margin-top: 20px;
    margin-bottom: 28px;
}

.user_details .address {
    margin-bottom: 28px;
}

.address p {
    margin: 0px;
}

.user_details .whatsapp_check_cont {
    display: flex;
    align-items: center;
    font-size: 14px;
}

.user_details .whatsapp_cont {
    margin-bottom: 30px;
}

.user_details .whatsapp_cont .custom-input {
    margin: 0px !important;
}

@media only screen and (min-width: 601px) {
    .basic_details_stepper {
        width: 50%;
        transform: translateX(-8%);
    }
}

.adornment {
    padding-bottom: 1px;
    padding-right: 4px;
    color: var(--fieldLabelColor);
}

