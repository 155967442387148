.verification-page{
  text-align: left;
}
.verification-page .bottom-section{
  text-align: center;
}
.verification-page .heading {
  font-size: 20px;
  color: var(--red);
  font-family: "LatoBold";
}
.verification-page .name{
  margin-top: 20px;
  margin-bottom: 28px;
}
.verification-page .heading-toggle{
  font-size: 16px;
  color: var(--red);
  font-weight: bold;
}
.verification-page .address{
  margin: 28px 0px;
}
.verification-page .address_check_cont{
  display: flex;
  align-items: center;
  font-size: 14px;
}
.resident-address .address p{
  color: var(--fontColor4);
  font-size: 16px;
}
.verification-page .address-heading {
  font-size: 16px;
  font-family: "LatoBold";
  margin-bottom: 22px;
}
.bold #alert-dialog-slide-description {
  font-family: "LatoBold";
}

.bold .italic-text {
  font-family: "LatoRegular";
}

/* ETB Address Page */
.verification-page .etb-user-details .heading{
  margin-bottom: 32px;
}
.verification-page .etb-user-details .address-contant{
  padding: 20px 25px;
  padding-bottom: 25px;
  box-shadow: 0px 1px 20px var(--boxShadowColor);
  margin-bottom: 24px;
}
.verification-page .etb-user-details .address-contant .custom-input{
  margin-bottom: 0;
}
.verification-page .etb-user-details .address-contant .custom-input input{
  margin-right: 10px;
}
.verification-page .etb-user-details .address-contant .address-sec{
  margin-bottom: 24px;
}
.address-sec p {
  margin: 0px !important;
}
.verification-page .etb-user-details .address-header {
  color: var(--red);
  font-weight: bold;
  font-style: "LatoBold";
  margin-bottom: 26px;
}
.verification-page .etb-user-details .address-contents{
  font-size: 14px;
  color: var(--fontColor4);
}

/* Self Employed Details */

.verification-page .self-employed .custom-input{
  margin-block: 40px;
}

.verification-page .self-employed .address_check_cont .custom-input{
  margin: 0;
}

/* Salary Employed Details */

.verification-page .salary-employed .custom-input{
  margin: 30px 0px;
}

.verification-page .employee-details .sub-heading{
  font-size: 16px;
  color: var(--red);
  font-family: "LatoBold";
  margin-bottom: 25px;
}

/*  Demographic Details Css */

.verification-page .demographic-details .custom-input{
  margin-top: 25px;
  margin-bottom: 0;
}
.verification-page .demographic-details .custom-input.custom-select{
  margin-top: 25px!important;
}


.permanentTerms .TermsAndConditionsWrapper {
  left: 0;
}

.heading .leadNumber {
  font-size: 16px;
  color: var(--fontColor5);
  font-family: "LatoRegular";
  margin-bottom: 0px;
}