.page-container {
  margin-top: -80px;
  min-height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
}
.journey-logo {
  width: 100px;
  padding: 15px 0px;
}
.loan-title-btn {
  background-color: var(--red);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  cursor: pointer;
}
.landing-page-body .MuiPaper-root {
  cursor: pointer;
}
.journey-action-button{
  color:white;
  font-size: 16px;
  font-family: 'LatoBold';
}
.bottom-background {
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: var(--bgColor);
  width: 100%;
}

/* Welcome Page */
.welcome-content {
  position: relative;
  z-index: 3;
  margin-top: 70px;
}
.welcome-bottom-text {
  background-color:rgb(135, 0, 22) !important;
  padding: 20px;
  color: var(--bgColor1) !important;
  font-weight: bold;
}
.welcome-text-box {
  margin-top: 30px !important;
  font-weight: bold;
  font-size: 32px;
  color: var(--fontColor2);
  float: left;
  width: 100%;
}
.welcome-img {
  width: 200px;
  min-height: 224px;
}
@media only screen and (max-width: 899px) {
  .journey-body-content{
    max-width: 700px;
    margin-inline: auto;
  }
}

@media only screen and (max-width: 500px) {
  .journey-body-content{
    min-width: 95vw;
  }
}