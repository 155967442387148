.top-header {
  text-align: left;
  padding-top: 10px;
}
.journey-title{
  font-size: 16px;
  color: var(--red);
  margin: 0px 0px 16px 0px !important;
  font-family: 'LatoBold';
  opacity: 0.9;
}
.welcome-title {
  font-size: 32px;
  color: var(--fontColor);
  margin: 0px 0px 10px 0px !important;
  opacity: 0.9;
}
.trancated-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.welcome-sub-title {
  font-size: 20px;
  color: var(--fontColor1);
  margin: 0px !important;
}
.welcome-phone-title, .welcome-email-title {
  margin: 0px 0px 5px 0px !important;
}
.welcome-phone-title svg, .welcome-email-title svg {
  color: var(--red);
  margin-right: 5px;
  vertical-align: middle;
}
.bottom-slider {
  margin-top: 30px;
}
.welcome-phone-title span, .welcome-email-title span {
  color: var(--fontColor3);
}
.font24 {
  font-size: 24px !important;
}