.MuiSlider-thumb {
    color: var(--red);
}

.MuiSlider-track {
    color: var(--red);
}

.MuiSlider-rail {
    color: var(--red);
}

.slider-component-root .MuiSlider-root {
    padding-top: 13px;
}