.income-terms-conditions-container {
    position: fixed;
    bottom: -100%;
    left: 0;
    width: 100%;
    z-index: 5;
    overflow: hidden;
    transition: 0.5s ease-in-out;
}

.income-terms-conditions-container.show-terms{
    padding-top: 50px;
    bottom: 0;
    height: 99vh;
}

.income-terms-heading svg {
    float: right;
    width: 35px;
    height: 35px;
    margin-top: -5px;
    cursor: pointer;
}

.income-terms-conditions-container .consent-action-button{
    background-color: var(--red)!important
}


@media only screen and (min-width: 601px) {
    .income-consent-content {
        height: 300px !important;
    }
    
}
.income-consent-content {
    text-align: left !important;
    height: calc(100vh - 260px) !important;
    float: left;
    overflow: scroll;
    scroll-behavior: smooth;
    color: var(--fontColor);
}
.income-consent-content::-webkit-scrollbar {
    display: none;
}
.terms-condition{
    font-family: "LatoBold";
    color: var(----fontColor);
}
.filedset-text{
    margin: 0px;
}