.otp-box {
    box-sizing: border-box;
    text-align: center;

}
.otp-root-cont {
    display: flex;
    align-items: center;
    gap: 10px;
    grid-gap: 10px;
    box-sizing: border-box;
    margin-top: 28px;
    margin-bottom: 28px;
}
.otp-input-error .otp-box {
    border: 1px solid red;
    outline: none;
    box-shadow: 1px 1px 2px rgba(255, 0, 0, 0.117);
}