.verifyIncome .close-terms{
    display: block;
}
.verifyIncome .open-terms{
    display: none;
}
.verifyIncome .close-terms > .heading{
    margin-bottom: 48px;
}

.verifyIncome > .heading{
    margin-bottom: 48px;
}
.verifyIncome .section{
    margin-bottom: 20px;
    text-align: start;
}
.verifyIncome .section .radio_btn_cont{
    display: flex;
    margin-bottom: 37px;
}
.verifyIncome .section .radio_btn_cont .radio_btn{
    height: 15px;
    width: 15px;
    border: 2px solid var(--radioBtnBorder);
    border-radius: 100%;
    /* color: white; */
    display: grid;
    place-items: center;
    transition: 0.25s ease-in-out;  
    cursor: pointer;
    margin-right: 10px;
    margin-top: 5px;
    transform: translateY(-5px);
}
.verifyIncome .section .radio_btn_cont .radio_btn:hover,
.verifyIncome .section .radio_btn_cont .radio_btn.active{
    border-color: var(--circleColor);
}

.verifyIncome .section .radio_btn_cont .radio_btn div{
    height: 13px;
    width: 13px;
    border-radius: 100%;
}
.verifyIncome .section .radio_btn_cont .radio_btn.active div{
    background-color: var(--red);
}

.verifyIncome .section .radio_btn_cont .label .main_label{
    font-size: 14px;
    color: var(--fontColor3)
}
.verifyIncome .section .radio_btn_cont .label .sub_label{
    font-size: 12px;
    color: var(--fontColor6);
    margin-top: 3px;
}


.verifyIncome .section .details_section{
    display: none;
}
.verifyIncome .section .details_section.active{
    display: block;
}
.verifyIncome .section .details_section .input_field{
    padding-bottom: 25px;
}
.verifyIncome .section .details_section .input_field.disable{
    display: none;
}

.verifyIncome .section .option_box{
    margin-block: 20px;
    padding: 28px 30px 25px 31px;
    box-shadow: 0px 0px 5px var(--boxShadowColor);
    border: 1px solid var(--fieldBorder);
    position: relative;
}
.verifyIncome .section .option_box .title{
    position: absolute;
    top: 0px;
    left: 10px;
    border-radius: 50px;
    border: 1px solid var(--fieldBorder);
    font-size: 12px;
    padding: 2px 10px;
    color: var(--fontColor1);
    background-color: var(--bgColor1);
    font-family: "LatoItalic";
    transform: translateY(-50%);
    font-style: italic;
}
.verifyIncome .section .option_box .heading{
    font-weight: bold;
    color: var(--fontColor);
    font-size: 14px;
    font-family: "LatoBold";
}
.verifyIncome .section .option_box .sub_heading{
    font-size: 12px;
    color: var(--fontColor);
    margin-top: 3px;
}
.verifyIncome .section .option_box .recomend{
    font-size: 12px;
    color: var(--bgColor3);
    font-family: "LatoItalic";
    font-style: italic;
    margin-top: 2px;
}
.verifyIncome .section .option_box .proceed_btn{
    margin-top: 20px;
    border: 2px solid var(--red);
    border-radius: 5px;
    font-size: 16px;
    padding: 6px 32px 6px 33px;
    width: fit-content;
    color: var(--red);
    font-family: "LatoBold";
    font-weight: bold;
    cursor: pointer;
    background : transparent;
}

.verifyIncome .section .option_box .proceed_btn:disabled{
    opacity: 0.5;
    cursor: default;
  }