.m-0 {
  margin: 0;
}

.disabled-label {
  color: var(--fieldLabelColor);
}

.sub-label {
  font-size: 0.8rem;
  margin-top: 4px;
  color: var(--fieldLabelColor);
}

.sub-label.disabled-label {
  font-size: 0.8rem;
  margin-top: 1px;
  color: var(--disableColor);
}

.custom-checkbox {
  position: relative;
  display: flex;
  align-items: flex-start;
}

.custom-checkbox .sub-label {
  text-align: left;
}

.page-body-checkbox p {
  text-align: left;
  margin: 0px 0px 0px 25px;
}

.page-body-checkbox {
  display: flex;
}
.css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
  color: var(--circleColor) !important; 
}
.MuiRadio-root {
  color: var(--radioBtnBorder) !important;
}
.MuiRadio-root .css-1hbvpl3-MuiSvgIcon-root {
  stroke-width: 1px;
}
.MuiRadio-root.Mui-checked .css-1hbvpl3-MuiSvgIcon-root {
  color: var(--stepperLine) !important;
}
.MuiRadio-root.Mui-checked .MuiSvgIcon-root {
  color: var(--red) !important;
}
.MuiRadio-root .css-11zohuh-MuiSvgIcon-root {
  transform: scale(1.25);
}

.relative {
  position: relative;
}

.search-input-dropdown {
  border: none;
  outline: none;
  background: transparent;
  width: 90%;
  position: absolute;
  height: 100%;
  z-index: 99;
  padding: 2px 7px;
}

.select-field-input {
  width: 100%;
}

.custom-select .MuiInputBase-root::after {
  border-bottom: 1px solid var(--red);
}

.custom-select {
  text-align: left;
}

.custom-input .field-label {
  float: unset !important;
  width: 100%;
  text-align: left !important;
  display: block;
}

.custom-input {
  text-align: left !important;
  margin-bottom: 20px;
}

.custom-input .checkbox-label {
  margin: 4px;
}

.custom-input .error {
  float: unset !important;
  text-align: left;
  margin: 0;
  margin-top: 2px;
}

.MuiAutocomplete-option {
  text-align: left;
}

.custom-input .MuiFormControlLabel-root.MuiFormControlLabel-labelPlacementEnd {
  display: flex;
  align-items: flex-start;
}
.custom-input .MuiButtonBase-root.MuiRadio-root {
  position: relative;
  top: -10px;
}
.custom-input .MuiTypography-root.MuiFormControlLabel-label {
  font-size: 14px;
  font-family: "LatoRegular";
  color: var(--fontColor3);
}

.custom-checkbox .checkbox-cover {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 20px;
  z-index: 5;
  background: transparent;
  cursor: pointer;
}

.MuiRadio-root.Mui-checked .MuiSvgIcon-root:first-child path {
  fill: var(--circleColor) !important;
}

@media only screen and (max-width: 500px) {
  .custom-input .MuiTypography-root.MuiFormControlLabel-label {
    /* font-size: 12px; */
  }
}