.videoKyc {
	justify-content: start;
	text-align: start;
	/* margin-bottom: 59px; */
}

.video-kyc-container {
	box-shadow: 0px 3px 6px var(--boxShadowColor);
}
.kyc-header-container {
	height: 60px;
}
.vkyc-body-header {
	color: var(--red);
	padding-bottom: 36px;
	font-size: 20px;
	font-family: "LatoBold";
}
.kyc-header-container .kyc-header {
	color: var(--bgColor1);
	padding: 20px;
	font-family: "LatoBold";
	font-size: 16px;
}
.kyc-body-container {
	padding: 20px 10px;
	line-height: 20px;
	font-size: 14px;
	color: var(--fontColor3);
	/* white-space: nowrap; */
}

.kyc-body-container ol {
	padding: 0px;
	padding-left: 15px;
	margin: 0;
}
.video-kyc-body-text {
	margin-bottom: 20px;
}

.assitent-Card{
	margin-top: 50px;
}