.roadmap_slider_cont{
    display: grid;
    place-items: center;
    margin: 10px 0px 20px 0px;
    margin-inline: auto;
}
.roadmap_slider_cont .roadmap_slider{
    height: 5px;
    background-color: white;
    overflow: visible;
    display: flex;
    align-items: center;
    position: relative!important;
    width: 100%;
}
.roadmap_slider_cont .roadmap_slider .bar{
    overflow-y: visible;
    position: relative!important;
    background-color: var(--red);
    color: var(--red);
    width: 80%;
    height: 5px;
}
.roadmap_slider_cont .roadmap_slider .bar svg{
    position: absolute;
    top: -11px;
    right: -20px;
}
