.name-consent-content .terms-content-wrapper{
    height: calc(100vh - 300px) !important;
    overflow: scroll;
    scroll-behavior: smooth;
}
.terms-conditions-container {
    height: calc(100vh - 100px);
}
.terms-conditions {
    color: var(--fontColor);
    font-weight: bold;
}
.name-consent-content {
    text-align: left !important;
    float: left;
    color: var(--fontColor);
}
.name-consent-content.active{
    transform: translateY(0%);
}
.name-consent-content .terms-content-wrapper::-webkit-scrollbar {
    display: none;
}
.terms-heading {
    margin: 0;
    background: var(--bgColor1);
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 0px 0px 10px 0px;
}
.terms-heading svg {
    float: right;
    width: 35px;
    height: 35px;
    margin-top: -5px;
    cursor: pointer;
}
.terms-accept {
    margin: 40px 0px;
}
.name-consent-title a {
    text-decoration: none;
    color: var(--linkColor);
    float: left;
    width: 100%;
    padding: 8px 0px;
}
.name-consent-title a:last-child {
    margin-bottom: 20px;
}
.consent-action-button {
    margin-bottom: -86px !important;
    text-transform: none !important;
    width: 100%;
    font-size: 20px !important;
    height: 40px !important;
}

ul {
    padding-left: 20px;
}
ul li {
    padding-bottom: 5px;
}

@media only screen and (max-width: 500px) {
    .name-consent-content .terms-content-wrapper{
        height: calc(100vh - 350px) !important;
    }
}
@media(min-height:730px) {
    .name-consent-content .terms-content-wrapper{
        height: calc(100vh - 380px) !important;
    }
}