.bottom-section {
  width: 100%;
  margin-top: auto;
  position: relative;
}
.action-button {
  /* margin-bottom: -200px !important;
  text-transform: none !important;
  background-color: var(--red) !important;
  width: 100%; */
  text-transform: none !important;
  background-color: var(--red) !important;
  width: 100%;
  min-width: 250px !important;
  /* transform: translateY(70px); */
}

.MuiFormControl-root {
  width: 100%;  
}
.bottom-section .Mui-disabled {
  background-color: var(--bgColor) !important;
  color: var(--fieldLabelColor) !important;
}

.prev-next-button {
  text-transform: none !important;
  font-size: 20px !important;
  height: 40px;
  font-weight: bold !important;
  background-color: #870016 !important;
  color: #fff !important;
  width: 30%;
  margin-right: 0.5rem !important;
}

@media only screen and (min-width: 600px) {
  .MuiFormControl-root, button {
    /* width: 100%; */
  }
  .action-button {
    width: 40%;
  }
}
@media only screen and (max-width: 600px) {
  .footer-button {
    position: absolute !important;
    bottom: 0 !important;
    overflow-y: hidden;
  }  
}
/* Loader Text */
.loader-text {
  width: 4rem;
  margin: auto;
}
.loader-text:after {
  content: "Loading";
  animation-name: loading-text;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}
@keyframes loading-text {
  0% {
    content: "Loading";
  }
  25% {
    content: "Loading.";
  }
  50% {
    content: "Loading..";
  }
  75% {
    content: "Loading...";
  }
}